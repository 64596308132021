<template>
  <div>
    <el-form :model="form" label-position="top" size="small" ref="form">
      <el-form-item :label="$t('registry')">
        <el-input v-model="form.kind" disabled></el-input>
      </el-form-item>

      <el-form-item
        :label="$t('name')"
        prop="name"
        :rules="[
          { required: true, message: this.$t('rules.require'), trigger: 'blur' },
          {
            pattern: /^[a-z0-9]([-a-z0-9]*[a-z0-9])?(\.[a-z0-9]([-a-z0-9]*[a-z0-9])?)*$/,
            message: $t('rules.pattern'),
            trigger: 'blur'
          }
        ]"
        v-if="type == 'add'"
      >
        <el-input v-model="form.name"></el-input>
        <span class="help">{{ $t("tips.nameCommonPattern") }}</span>
      </el-form-item>

      <el-form-item label="所属集群">
        <el-select v-model="providerRegistryBindings" filterable multiple style="width: 100%;">
          <el-option v-for="item in providers" :key="item.uuid" :label="item.cluster" :value="item.uuid"> </el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        :label="$t('region')"
        v-if="['RegistryAWS', 'RegistryAliACR', 'RegistryTencentTCR', 'RegistryHuaweiSWR'].includes(form.kind)"
        prop="regionID"
        :rules="{ required: true, message: this.$t('rules.require'), trigger: 'blur' }"
      >
        <el-input v-model="form.regionID" @change="regionBlur"></el-input>
      </el-form-item>

      <el-form-item
        label="AccessKey"
        v-if="['RegistryAWS', 'RegistryAliACR', 'RegistryTencentTCR'].includes(form.kind)"
        prop="accessKey"
        :rules="{ required: true, message: this.$t('rules.require'), trigger: 'blur' }"
      >
        <el-input v-model="form.accessKey"></el-input>
      </el-form-item>

      <el-form-item
        label="SecretAccessKey"
        v-if="['RegistryAWS', 'RegistryAliACR', 'RegistryTencentTCR'].includes(form.kind)"
        prop="secretAccessKey"
        :rules="{ required: true, message: this.$t('rules.require'), trigger: 'blur' }"
      >
        <el-input v-model="form.secretAccessKey"></el-input>
      </el-form-item>

      <el-form-item
        label="Scheme"
        v-if="['RegistryHarbor', 'RegistryHarborv2', 'RegistryUhub'].includes(this.form.kind)"
      >
        <el-radio-group v-model="form.scheme">
          <el-radio label="http" border>HTTP</el-radio>
          <el-radio label="https" border>HTTPS</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item
        label="Account"
        v-if="['RegistryHuaweiSWR'].includes(form.kind)"
        prop="account"
        :rules="{ required: true, message: this.$t('rules.require'), trigger: 'blur' }"
      >
        <el-input v-model="form.account"></el-input>
      </el-form-item>

      <el-form-item
        label="AccountPassword"
        v-if="['RegistryHuaweiSWR'].includes(form.kind)"
        prop="accountPassword"
        :rules="{ required: true, message: this.$t('rules.require'), trigger: 'blur' }"
      >
        <el-input v-model="form.accountPassword" type="password"></el-input>
      </el-form-item>

      <el-form-item
        label="DomainName"
        v-if="['RegistryHuaweiSWR'].includes(form.kind)"
        prop="domainName"
        :rules="{ required: true, message: $t('rules.require'), trigger: 'blur' }"
      >
        <el-input v-model="form.domainName"></el-input>
      </el-form-item>

      <el-form-item
        label="ProjectName"
        v-if="['RegistryHuaweiSWR', 'RegistryHarbor', 'RegistryHarborv2'].includes(form.kind)"
        prop="projectName"
        :rules="{ required: true, message: $t('rules.require'), trigger: 'blur' }"
      >
        <el-input v-model="form.projectName"></el-input>
      </el-form-item>

      <el-form-item
        label="RegistryID"
        v-if="['RegistryTencentTCR'].includes(form.kind)"
        prop="registryID"
        :rules="{ required: true, message: this.$t('rules.require'), trigger: 'blur' }"
      >
        <el-input v-model="form.registryID"></el-input>
      </el-form-item>

      <el-form-item label="APIHost" v-if="['RegistryUhub'].includes(form.kind)">
        <el-input v-model="form.apiHost"></el-input>
      </el-form-item>

      <el-form-item
        label="Host"
        prop="host"
        :rules="{ required: true, message: this.$t('rules.require'), trigger: 'blur' }"
      >
        <el-input v-model="form.host"></el-input>

        <span class="help" v-if="['RegistryHuaweiSWR'].includes(form.kind)"> {{ $t("imageHostTips.SWRHost") }} </span>
        <span class="help" v-if="['RegistryTencentTCR'].includes(form.kind)"> {{ $t("imageHostTips.TCRHost") }} </span>
        <span class="help" v-if="['RegistryUhub'].includes(form.kind)">例如: uhub.service.ucloud.cn </span>
      </el-form-item>

      <el-form-item
        label="RepoNamespace"
        v-if="['RegistryTencentTCR', 'RegistryHuaweiSWR', 'RegistryAliACR'].includes(form.kind)"
        prop="repoNamespace"
        :rules="{ required: true, message: this.$t('rules.require'), trigger: 'blur' }"
      >
        <el-input v-model="form.repoNamespace"></el-input>
      </el-form-item>

      <el-form-item :label="$t('JSONSecret')" v-if="this.form.kind == 'RegistryGCP'">
        <editor v-model="form.gcpConfJson" @init="jsonEditorInit" lang="json" theme="cobalt" width="100%" height="200">
        </editor>
      </el-form-item>

      <el-form-item
        label="RepoName"
        v-if="['RegistryUhub'].includes(form.kind)"
        prop="repoName"
        :rules="{ required: true, message: this.$t('rules.require'), trigger: 'blur' }"
      >
        <el-input v-model="form.repoName"></el-input>
      </el-form-item>

      <el-form-item
        label="PublicKey"
        v-if="['RegistryUhub'].includes(form.kind)"
        prop="publicKey"
        :rules="{ required: true, message: this.$t('rules.require'), trigger: 'blur' }"
      >
        <el-input v-model="form.publicKey"></el-input>
        <span class="help">
          访问UCLOUD API的公钥，可以从UCLOUD的<a href="https://console.ucloud.cn/uapi/apikey">控制台</a>获取
        </span>
      </el-form-item>

      <el-form-item
        label="PrivateKey"
        v-if="['RegistryUhub'].includes(form.kind)"
        prop="privateKey"
        :rules="{ required: true, message: this.$t('rules.require'), trigger: 'blur' }"
      >
        <el-input v-model="form.privateKey"></el-input>
      </el-form-item>

      <el-form-item label="ProjectId" v-if="['RegistryUhub'].includes(form.kind)">
        <el-input v-model="form.projectId"></el-input>
        <span class="help">项目id，如果是默认项目，其他情况则不可为空</span>
      </el-form-item>

      <el-card
        shadow="never"
        class="more-conf"
        v-if="
          [
            'RegistryDocker',
            'RegistryAliACR',
            'RegistryTencentTCR',
            'RegistryHuaweiSWR',
            'RegistryHarbor',
            'RegistryHarborv2',
            'RegistryUhub'
          ].includes(form.kind)
        "
      >
        <div slot="header">
          {{ $t("moreConf") }}
        </div>

        <div>
          <div
            class="more-conf-item"
            v-if="
              [
                'RegistryDocker',
                'RegistryAliACR',
                'RegistryTencentTCR',
                'RegistryHuaweiSWR',
                'RegistryHarbor',
                'RegistryHarborv2',
                'RegistryUhub'
              ].includes(form.kind)
            "
          >
            <div>
              <div class="title">{{ $t("needAuth") }}</div>
              <div class="desc">{{ $t("registryTips.needAuth") }}</div>
            </div>

            <el-switch v-model="form.auth"> </el-switch>
          </div>

          <el-form-item
            label="AccessKey"
            v-if="['RegistryHuaweiSWR'].includes(form.kind) && form.auth"
            prop="accessKey"
            :rules="{ required: true, message: this.$t('rules.require'), trigger: 'blur' }"
          >
            <el-input v-model="form.accessKey"></el-input>
          </el-form-item>

          <el-form-item
            label="SecretAccessKey"
            v-if="['RegistryHuaweiSWR'].includes(form.kind) && form.auth"
            prop="secretAccessKey"
            :rules="{ required: true, message: this.$t('rules.require'), trigger: 'blur' }"
          >
            <el-input v-model="form.secretAccessKey"></el-input>
          </el-form-item>

          <el-form-item
            :label="$t('userName')"
            prop="username"
            :rules="{ required: true, message: this.$t('rules.require'), trigger: 'blur' }"
            v-if="
              [
                'RegistryDocker',
                'RegistryAliACR',
                'RegistryTencentTCR',
                'RegistryHarbor',
                'RegistryHarborv2',
                'RegistryUhub'
              ].includes(form.kind) && form.auth
            "
          >
            <el-input v-model="form.username"></el-input>
          </el-form-item>

          <el-form-item
            :label="$t('password')"
            prop="password"
            :rules="{ required: true, message: this.$t('rules.require'), trigger: 'blur' }"
            v-if="
              [
                'RegistryDocker',
                'RegistryAliACR',
                'RegistryTencentTCR',
                'RegistryHarbor',
                'RegistryHarborv2',
                'RegistryUhub'
              ].includes(form.kind) && form.auth
            "
          >
            <el-input v-model="form.password" type="password"></el-input>
            <span class="help" v-if="['RegistryUhub'].includes(form.kind)"
              >拉取镜像时所用到的密码, 您可能设置了独立的密码, 请确认后填写</span
            >
          </el-form-item>

          <el-form-item
            :label="$t('email')"
            prop="email"
            :rules="{ type: 'email', message: this.$t('rules.login.email'), trigger: ['blur', 'change'] }"
            v-if="this.form.kind == 'RegistryDocker' && form.auth"
          >
            <el-input v-model="form.email"></el-input>
          </el-form-item>

          <el-form-item :label="$t('ca')" v-if="this.form.kind == 'RegistryDocker' && form.auth">
            <el-input v-model="form.ca" type="textarea" :rows="6"></el-input>
          </el-form-item>

          <!-- <div class="more-conf-item">
            <div>
              <div class="title">{{ $t("handle.registryTest") }}</div>
              <div class="desc">{{ $t("registryTips.test") }}</div>
            </div>
            <el-button type="primary" @click="registryTest" size="small" plain :loading="testLoading">
              <span v-if="!testLoading">{{ $t("handle.test") }}</span>
              <span v-else>{{ $t("handle.testing") }}</span>
            </el-button>
          </div> -->

          <!-- <div v-if="testContainerShow">
            <el-alert title="Success" type="success" show-icon v-if="testStatus"> </el-alert>
            <el-alert title="Error" type="error" show-icon v-if="!testStatus"> </el-alert>
          </div> -->
        </div>
      </el-card>

      <slot name="button"></slot>
    </el-form>
  </div>
</template>

<script>
import { registryTest } from "api/registry";
import { providers } from "api/app";

export default {
  props: {
    form: Object,
    type: String
  },

  components: {
    editor: require("vue2-ace-editor")
  },

  data() {
    return {
      testLoading: false,
      testContainerShow: false,
      testStatus: false,
      providers: [],
      providerRegistryBindings: []
    };
  },
  methods: {
    jsonEditorInit: function() {
      require("brace/ext/searchbox");
      require("brace/mode/json");
      require("brace/theme/cobalt");
    },

    regionBlur() {
      if (this.form.kind == "RegistryAliACR" && this.form.regionID) {
        this.form.host = `registry.${this.form.regionID}.aliyuncs.com`;
      }
    },

    registryTest() {
      this.testLoading = true;
      this.testContainerShow = false;
      registryTest(this.form).then(response => {
        this.testLoading = false;
        if (response.code === 0) {
          this.testContainerShow = true;
          this.testStatus = response.data;
        }
      });
    },

    getProviders() {
      providers(this.organization).then(response => {
        if (response.code === 0) {
          this.providers = response.data.items;
        }
      });
    }
  },

  mounted() {
    this.getProviders();
  },

  computed: {
    organization() {
      return this.$store.state.app.organization;
    }
  }
};
</script>
